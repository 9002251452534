import { GenericTranscriptionType, WordType } from "../../types";

export default class TranscriptionTextMutations {
  private times: number[][];
  public rawTextSentences: string[];
  public rawTextParagraphs: string[];

  constructor(transcription: GenericTranscriptionType, minCharLength = 400) {
    const { words } = transcription;
    const wordTimes = words.map(word => word.startTime);
    const sentences = this.makeSentences(words);

    this.rawTextSentences = this.makeSentences(words);
    this.rawTextParagraphs = this.makeParagraphs(
      this.rawTextSentences,
      minCharLength
    );

    this.times = moldArr(sentences.map(s => s.split(" ").length), wordTimes);
    this.times = moldArr(
      this.rawTextParagraphs.map(p => p.split(" ").length),
      wordTimes
    );
  }

  public getTimeFromIndices(paragraphIndex: number, wordIndex: number): number {
    return this.times[paragraphIndex][wordIndex];
  }

  private makeSentences(words: WordType[]): string[] {
    const oneBigSentence = words.map(word => word.guessedContent).join(" ");
    const possibleSplits = oneBigSentence.match(
      /([^\.!\?]+[\.!\?]+)|([^\.!\?]+$)/g
    );
    return possibleSplits
      ? possibleSplits.map(s => s.trim())
      : [oneBigSentence];
  }

  private makeParagraphs(sentences: string[], minCharLength: number): string[] {
    const arr: string[] = [];
    let currentLength = 0;
    let currentContents: string[] = [];
    sentences.forEach(sentence => {
      currentContents.push(sentence);
      const nextLength = sentence.length + currentLength;
      if (nextLength > minCharLength) {
        arr.push(currentContents.join(" "));
        currentContents = [];
        currentLength = 0;
      } else {
        currentLength = nextLength;
      }
    });
    if (currentContents.length) arr.push(currentContents.join(" "));
    return arr;
  }
}

export function moldArr<T>(lengthArray: number[], unmoldedArray: T[]): T[][] {
  const final: T[][] = [];
  let i = 0;
  lengthArray.forEach(len => {
    final.push(unmoldedArray.slice(i, i + len));
    i += len;
  });
  return final;
}
