import * as React from "react";
import { observer } from "mobx-react";
import styled from "@emotion/styled";

import { getStores } from "../../stores";

const Container = styled.div`
  margin-left: 16px;
  width: 100%;
  height: 16px;
  border: 1px solid #fc561e;
  border-radius: 4px;
  cursor: pointer;

  div {
    height: 100%;
    border-radius: 3px 0 0 3px;
    background-color: #8dd7a4;
  }
`;

const ProgressBar: React.SFC = observer(() => {
  function handleSeekTrack(e: any) {
    const { seek, duration } = getStores().audioStore;

    const percent =
      (e.pageX - e.currentTarget.getBoundingClientRect().left) /
      e.currentTarget.offsetWidth;

    const newPos = percent * duration;
    seek(newPos, false);
  }

  const { track, currentTime, duration, isLoading } = getStores().audioStore;

  const value =
    !track || !duration
      ? 0
      : Math.min(Math.max((currentTime / duration) * 100 || 0, 0), 100);

  const clickHandler = isLoading ? undefined : handleSeekTrack;

  return (
    <Container onClick={clickHandler}>
      <div style={{ width: `${value}%` }} />
    </Container>
  );
});

export default ProgressBar;
