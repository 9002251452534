import { action, computed, observable, reaction } from "mobx";
import { initKeyListeners } from "../components/common/keys";
import AudioElementTrack from "../tracks/papio-audio-element-track";
import SoundcloudTrack from "../tracks/soundcloud-track";
import { Track } from "../types";

export default class AudioStore {
  constructor() {
    if (typeof window !== "undefined") {
      initKeyListeners({
        " ": () => (this.isPlaying ? this.pause() : this.play())
      });
    }

    reaction(
      () => this.currentTime,
      () => {
        if (this.seekJustTriggered) {
          this.seekJustTriggered = false;
        }
      }
    );
  }

  @observable
  public track?: Track;

  @computed
  public get trackLoaded(): boolean {
    return !!this.track;
  }

  @observable
  public isPlaying: boolean = false;

  @observable
  private waitingOnBasicAudioAction: boolean = false;

  @observable
  private seekJustTriggered: boolean = false;

  @computed
  public get isLoading(): boolean {
    return (
      !this.trackLoaded ||
      !this.duration ||
      this.waitingOnBasicAudioAction ||
      this.seekJustTriggered
    );
  }

  @observable
  public currentTime: number = 0;

  @observable
  public duration: number = 0;

  @action
  public loadTrack = (url: string): Promise<void> => {
    if (url.includes("soundcloud.com")) {
      this.track = new SoundcloudTrack(url);
      this.track.on("timeupdate", (currentTime: number) => {
        this.currentTime = currentTime;
      });

      this.track.getDuration().then(duration => (this.duration = duration));
    } else if (["mp3", "wav"].filter(type => url.includes(type)).length) {
      this.track = new AudioElementTrack(url);
      this.track.on("timeupdate", (currentTime: number) => {
        this.currentTime = currentTime;
      });
      this.track.getDuration().then(duration => (this.duration = duration));
    } else {
      console.log("This type of track type is not yet supported.");
    }

    return Promise.resolve();
  };

  @action
  public play = async (): Promise<void> => {
    if (!this.track) return;
    return this.basicAudioAction(this.track.play, true);
  };

  @action
  public pause = async (): Promise<void> => {
    if (!this.track) return;
    return this.basicAudioAction(this.track.pause, false);
  };

  @action
  public seek = async (
    seconds: number,
    play: boolean = false
  ): Promise<void> => {
    if (!this.track) return;
    this.seekJustTriggered = true;
    const seekAction = this.track.seek.bind(null, seconds);
    if (play) {
      return this.play().then(() =>
        this.basicAudioAction(seekAction, this.isPlaying)
      );
    }
    return this.basicAudioAction(seekAction, this.isPlaying);
  };

  @action
  private basicAudioAction = async (
    action: () => Promise<void>,
    isPlayingOnSuccess: boolean
  ): Promise<void> => {
    if (this.track) {
      this.waitingOnBasicAudioAction = true;
      return Promise.resolve(
        action()
          .then(() => {
            this.isPlaying = isPlayingOnSuccess;
          })
          .catch(e =>
            console.log(`Tried executing ${action} but failed with:`, e)
          )
          .finally(() => (this.waitingOnBasicAudioAction = false))
      );
    }
  };
}
