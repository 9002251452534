import * as React from "react";
import styled from "@emotion/styled";

import { getImage } from "../helpers";
import { Recording } from "../types";
import { listToSentence } from "../utils";
import Section from "./common/section";

export interface SectionProps {
  recording: Recording;
}

const Container = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(12, 1fr);

  @media only screen and (max-width: 500px) {
    display: block;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  grid-column: span 4;
  img {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  grid-column: span 8;
`;

const RecordingInfo: React.SFC<SectionProps> = ({
  recording: { title, description, date, speakers, image }
}): JSX.Element => (
  <Section>
    <Container>
      <ImageContainer>
        <img src={getImage(image)} alt="Recording Image" />
      </ImageContainer>
      <TextContainer>
        <h3>{title}</h3>
        <p>{description}</p>
        <p>Recorded on {date.formatted}</p>
        <p>Speakers: {listToSentence(speakers.map(s => s.name))}</p>
      </TextContainer>
    </Container>
  </Section>
);

export default RecordingInfo;
