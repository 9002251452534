import * as React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react";

import { getStores } from "../../stores";
import { FaPause, FaPlay } from "react-icons/fa";
import Spinner from "../common/spinner";

const Container = styled.div`
  background-color: #fc561e;
  color: #122523;
  border-radius: 50%;

  width: 60px;
  min-width: 60px;
  height: 60px;

  svg {
    height: 30px;
    width: 30px;
  }

  & > div {
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }
`;

const PlayPauseButton: React.SFC = observer(() => {
  const { isPlaying, play, pause, isLoading } = getStores().audioStore;

  const icon = isLoading ? <Spinner /> : isPlaying ? <FaPause /> : <FaPlay />;
  const action = isLoading ? undefined : isPlaying ? pause : play;

  return (
    <Container onClick={action}>
      <div>{icon}</div>
    </Container>
  );
});

export default PlayPauseButton;
