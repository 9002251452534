import * as React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  border: 1px solid rgba(200, 200, 200, 0.2);

  margin: 24px 0;
  padding: 16px;
  border-radius: 4px;

  position: relative;

  box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 600px) {
    margin: 16px 0;
    padding: 8px;
  }
`;

export interface SectionProps {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
}

const Section: React.SFC<SectionProps> = ({
  children,
  className
}): JSX.Element => (
  <Container className={className || ""}>{children}</Container>
);

export default Section;
