import { observer } from "mobx-react";
import * as React from "react";

import MetaKeywords from "../components/meta-keywords";
import RecordingInfo from "../components/recording-info";
import SoundPlayer from "../components/sound-player/sound-player";
import TranscriptionText from "../components/transcription-text/transcription-text";
import { Recording } from "../types";
import { GenericTranscriptionType } from "../types/copied-papio-api-types";

export interface TranscriptionTemplateProps {
  recording: Recording;
  actualTranscription: GenericTranscriptionType;
}

@observer
export default class TranscriptionTemplate extends React.Component<{
  pathContext: TranscriptionTemplateProps;
}> {
  public render() {
    const { actualTranscription, recording } = this.props.pathContext;
    return (
      <div>
        <MetaKeywords tags={recording.tags} />
        <SoundPlayer url={recording.audioUrl} />
        <RecordingInfo recording={recording} />
        <TranscriptionText transcription={actualTranscription} />
      </div>
    );
  }
}
