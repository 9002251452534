import * as React from "react";
import { observer } from "mobx-react";
import { css } from "@emotion/core";

import { getStores } from "../../stores";
import Section from "../common/section";
import PlayPauseButton from "./play-pause-button";
import ProgressBar from "./progress-bar";

export interface SoundPlayerProps {
  url: string;
}

const playerStyles = css`
  margin-top: 0;
  align-items: center;
  display: flex;

  @media only screen and (max-width: 600px) {
    position: sticky;
    top: 12px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

@observer
export default class ProgressSoundPlayer extends React.Component<
  SoundPlayerProps
> {
  public componentDidMount() {
    const { loadTrack } = getStores().audioStore;
    loadTrack(this.props.url);
  }

  public render() {
    const { isLoading } = getStores().audioStore;

    return (
      <Section
        css={playerStyles}
        className={isLoading ? "papio-player--disabled" : ""}
      >
        <PlayPauseButton />
        <ProgressBar />
      </Section>
    );
  }
}
