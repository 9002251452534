import gql from "graphql-tag";

import { isNumber } from "util";
import client from "../graphql/client";
import { Track, TrackEvent } from "../types";
import {
  GetPresignedUrl,
  GetPresignedUrlVariables
} from "./__generated__/GetPresignedUrl";

const mutation = gql`
  mutation GetPresignedUrl($url: URL!) {
    makeSignedUrl(url: $url)
  }
`;

// TODO: handle when link expires

export default class AudioElementTrack implements Track {
  private url: string;
  private latestSignedUrl: string | null = null;
  private audioElement: HTMLAudioElement | undefined;
  private preloadedingPromise: Promise<void> | null = null;

  constructor(url: string) {
    this.url = url;
  }

  private waitOnMetadataToLoad(audio: HTMLAudioElement): Promise<void> {
    return new Promise((resolve, reject) => {
      audio.onloadedmetadata = () => {
        if (isNumber(audio.duration)) {
          resolve();
        } else {
          reject(
            "Waited for Metadata to load but it does not seem like this is a proper audio file with a duration..."
          );
        }
      };
    });
  }

  private makeSurePreloaded(): Promise<void> {
    if (this.preloadedingPromise) {
      return this.preloadedingPromise;
    }

    if (this.latestSignedUrl) {
      return Promise.resolve();
    }

    this.preloadedingPromise = client
      .mutate<GetPresignedUrl, GetPresignedUrlVariables>({
        mutation,
        variables: { url: this.url }
      })
      .then(({ data }) => {
        if (data) {
          this.latestSignedUrl = data.makeSignedUrl;
          this.audioElement = new Audio(this.latestSignedUrl || undefined);
          // this.audioElement.onerror = event => {
          //   this.latestSignedUrl = null;
          //   this.makeSurePreloaded();
          // };
          return this.waitOnMetadataToLoad(this.audioElement);
        } else {
          throw new Error("Problem getting data from Presigned URL Request.");
        }
      })
      .catch(error => {
        console.log("Could not get presigned URL:", error);
      })
      .finally(() => {
        this.preloadedingPromise = null;
      });

    return this.preloadedingPromise;
  }

  public getDuration = (): Promise<number> => {
    return this.makeSurePreloaded().then(() => {
      return Promise.resolve(
        (this.audioElement && this.audioElement.duration) || 0
      );
    });
  };

  public play = (): Promise<void> => {
    return this.makeSurePreloaded().then(
      () => this.audioElement && this.audioElement.play()
    );
  };

  public pause = (): Promise<void> => {
    return this.makeSurePreloaded().then(
      () => this.audioElement && this.audioElement.pause()
    );
  };

  public seek = (seconds: number): Promise<void> => {
    console.log("seek");
    return this.makeSurePreloaded().then(() => {
      if (this.audioElement) {
        this.audioElement.currentTime = seconds;
      }
    });
  };

  // TODO: change any on callback type
  public on = (event: TrackEvent, callback: any) => {
    if (event === "timeupdate") {
      this.makeSurePreloaded().then(() => {
        if (this.audioElement) {
          this.audioElement.addEventListener("timeupdate", () => {
            callback(this.audioElement && this.audioElement.currentTime);
          });
        }
      });
    } else {
      console.log("Tried registering an event that does not exist.");
    }
  };
}
