import * as React from "react";
import { Helmet } from "react-helmet";

interface Props {
  tags: string[];
}

const MetaKeywords = ({ tags }: Props) =>
  Object.keys(tags).length ? (
    <Helmet>
      {tags ? <meta name="keywords" content={tags.join(",")} /> : null}
    </Helmet>
  ) : null;

export default MetaKeywords;
