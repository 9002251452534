import * as React from "react";
import * as QueryString from "query-string";
import styled from "@emotion/styled";

import { getStores } from "../../stores";
import { GenericTranscriptionType } from "../../types";
import TranscriptionTextMutations from "./transcription-text-mutations";
import Section from "../common/section";

export interface TranscriptionTextProps {
  transcription: GenericTranscriptionType;
}

export interface TranscriptionTextState {
  transcriptionContent: JSX.Element;
  hoveredParagraphIndex: number;
}

const Container = styled.div`
  text-align: center;
`;

const HoveredParagraph = styled.p`
  span:hover {
    font-style: italic;
    cursor: pointer;
  }
`;

export default class TranscriptionText extends React.Component<
  TranscriptionTextProps,
  TranscriptionTextState
> {
  private textMutations: TranscriptionTextMutations;
  constructor(props: TranscriptionTextProps) {
    super(props);

    this.textMutations = new TranscriptionTextMutations(props.transcription);

    this.state = {
      transcriptionContent: this.getLatestContent(),
      hoveredParagraphIndex: -1
    };
  }

  public componentDidMount() {
    const wordIndex = QueryString.parse(location.search).wordIndex as
      | string
      | undefined;
    if (wordIndex) {
      // TODO: make this a reaction in the future?
      const startWord = this.props.transcription.words[parseInt(wordIndex)];

      const { seek } = getStores().audioStore;
      seek(startWord.startTime, false);
    }
  }

  public componentDidUpdate(
    _: TranscriptionTextProps,
    prevState: TranscriptionTextState
  ) {
    if (prevState.hoveredParagraphIndex !== this.state.hoveredParagraphIndex) {
      this.setState({
        transcriptionContent: this.getLatestContent()
      });
    }
  }

  private handleWordClick = (e: any, paragraphIndex: number): void => {
    const time = this.textMutations.getTimeFromIndices(
      paragraphIndex,
      e.target.getAttribute("data-tag")
    );
    getStores().audioStore.seek(time);
  };

  private getLatestContent = (): JSX.Element => {
    const paragraphs = this.textMutations.rawTextParagraphs.map((p, i) => {
      const setHoverIndex = () => this.setState({ hoveredParagraphIndex: i });

      if (this.state && this.state.hoveredParagraphIndex === i) {
        const spans = p.split(" ").map((word, j) => (
          <span data-tag={j} key={`word${i}${j}`}>
            {" "}
            {word}
          </span>
        ));

        return (
          <HoveredParagraph
            key="hoverParagraph"
            onClick={e => this.handleWordClick(e, i)}
          >
            {spans}
          </HoveredParagraph>
        );
      }

      return (
        <p
          onMouseEnter={setHoverIndex}
          onTouchStart={setHoverIndex}
          key={`p${i}`}
        >
          {p}
        </p>
      );
    });
    return <div>{paragraphs}</div>;
  };

  public render() {
    const { transcriptionContent } = this.state;

    return (
      <Section>
        <Container>{transcriptionContent}</Container>
      </Section>
    );
  }
}
