import { Track, TrackEvent } from "../types";
const SoundCloudAudio = require("soundcloud-audio");
const myClientId = "88858454eff3af1661236f7b728ad7fd";

export default class SoundcloudTrack implements Track {
  public url: string;
  public soundcloudAudio: any;
  private preloaded: boolean = false;
  private trackData: any;

  constructor(url: string) {
    this.url = url;
    this.soundcloudAudio = new SoundCloudAudio(myClientId);
  }

  private resolveSoundcloudData = (): Promise<any> => {
    this.trackData = new Promise(resolve => {
      this.soundcloudAudio.resolve(this.url, (trackData: any) => {
        this.preloaded = true;
        this.trackData = trackData;
        resolve();
      });
    });
    return this.trackData;
  };

  private makeSurePreloaded = (): Promise<void> =>
    this.preloaded ? Promise.resolve() : this.resolveSoundcloudData();

  public getDuration = (): Promise<number> =>
    this.makeSurePreloaded().then(() => this.trackData.duration / 1000);

  public play = (): Promise<void> =>
    this.makeSurePreloaded().then(() => this.soundcloudAudio.play());

  public pause = (): Promise<void> =>
    this.makeSurePreloaded().then(() => this.soundcloudAudio.pause());

  public seek = (seconds: number): Promise<void> =>
    this.makeSurePreloaded().then(async () => {
      if (!this.soundcloudAudio.audio.readyState) {
        await this.play();
        await this.pause();
      }
      this.soundcloudAudio.audio.currentTime = seconds;
    });

  // TODO: change any on callback type
  public on = (event: TrackEvent, callback: any) => {
    if (event === "timeupdate") {
      this.soundcloudAudio.on("timeupdate", () =>
        callback(this.soundcloudAudio.audio.currentTime)
      );
    } else {
      console.log("Tried registering an event that does not exist.");
    }
  };
}
